import React, { Component } from 'react';
import {
  ChakraProvider,
  Button,
  HStack,
  Code,
} from '@chakra-ui/react';

import { LoginForm } from './LoginSystem/LoginForm'
import Report from './Components/Report';
import theme  from './theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      user: sessionStorage.getItem('name'),
      isAuth:  sessionStorage.getItem('isAuth'),
      message: "",
    }
    this.handleAuth.bind();
  }

  handleAuth(user, isAuth){
      let message = "";

      if(!isAuth){
        message = "Invalid Details, Try again!"
      }
      sessionStorage.setItem('name', user.name);
      sessionStorage.setItem('isAuth', true);
      this.setState({user: user.name, isAuth:isAuth, message})
  }

  logout(){
      this.setState({user: {}, isAuth:false})
      sessionStorage.clear();
  }

  render(){
    if (!this.state.isAuth){
    return (
      <div>
      <ChakraProvider theme={theme}>
        <LoginForm callback={this.handleAuth.bind(this)}/><br/> 
        {this.state.message}
      </ChakraProvider>

      </div>
    )
    }
    return (
      <div>
        <ChakraProvider theme={theme}>
          <HStack padding ="2" justify={["flex-end"]}>
           <Code colorScheme="whatsapp" variant="outline" padding="2"> {this.state.user}</Code><Button leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />} colorScheme='whatsapp' onClick={this.logout.bind(this)}>Logout</Button>
          </HStack>
          <Report /> 
      </ChakraProvider>
      </div>
      )
  }
}
export default App


