import ReportTable from './ReportTable';
import { Component } from 'react';
import { 
	Grid, 
	GridItem, 
	Badge,
} from '@chakra-ui/react';
import Notes from './Notes'


class Report extends Component{
  constructor(props){
    super(props);
    this.state = {
    	startDate: new Date(),
    	endDate: new Date(),
    }
    this.setDateRange = this.setDateRange.bind(this);
  }

  setDateRange(start, end){
  	this.setState({
  		startDate: start, 
  		endDate: end,
  	})
  }
  render(){
     return (
     	<Grid h="100px" templateRows="repeat(2, 1fr)" templateColumns="repeat(12, 1fr)" gap={4}>
			<GridItem colSpan={1} / >
     		<GridItem colSpan={10 }  >
     		<Badge variant="outline" colorScheme="whatsapp"> {sessionStorage.getItem('user')} </Badge>
      </GridItem>
			<GridItem colSpan={1} />

			<GridItem colSpan={1} />

     	<GridItem colSpan={10}  >
			<ReportTable startDate={this.state.startDate} endDate={this.state.endDate}/>
			</GridItem>
      <GridItem colSpan={1} />
      <GridItem colSpan={1} />
      <GridItem colSpan={10} ><Notes/></GridItem>
      <GridItem colSpan={1} />



		</Grid>

      );
};
}

export default Report;
