let AppConfig = {
	APIUrl: "https://mxz3xzpi8a.execute-api.eu-west-2.amazonaws.com/Stage/m_report",
	resultsPerPage: 10,
	APIKey: "2ITr2oeyj34nkHt7boFTl7I5FVCQhTEV3aVPWLNO",
	merchantKey: "241aae4e-b9a2-40d2-ac33-908412a80453",
	spoofAPI: "no",
}

export default AppConfig;


