import {Box} from '@chakra-ui/react';
import {WarningTwoIcon} from '@chakra-ui/icons'

function Notes(){
		return(
			<div>
			<center>
				<Box bg="white" padding="3" width="20%" color="black" margin="4"> 
					<WarningTwoIcon w={5} h={5} color="red.500" marginBottom="2" />
					<br/>DEV DATA:<br /> 
				 	AWS API Demo Merchant! 
				</Box>
			</center>
			</div>
		)
}

export default Notes