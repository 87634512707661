import React, {Component} from 'react'
import doLogin from './LoginFunctions';
import {LockIcon} from '@chakra-ui/icons'


import {
  Button,
  VStack,
  FormControl,
  Input,
  Heading,

} from '@chakra-ui/react';
import Notes from '../Components/Notes'




class LoginForm extends Component{
	constructor(props){
		super(props);
		this.callback = this.props.callback;
		this.state = {
			email: "",
			password: "",
		}
	}

	handleEmailChange(event){
		this.setState({email: event.target.value})
	}
	handlePasswordChange(event){
		this.setState({password: event.target.value})
	}

	checkForEnter(event){
		if (this.state.email !== "" && this.state.password !== ""){
			if(event.key === "Enter"){
				this.props.callback(this);
			}
		}
	}


	render(){
		return(
			<>
				<center>
					<VStack  width="50%" marginTop="50px" spacing={4}>
					<LockIcon w={100} h={100} />

					<Heading> Messaging Report Login: </Heading>

					<FormControl id="email" spacing={4} isRequired>
		  				<Input variant='filled' bg="black" placeholder="Email" type="email" onChange={this.handleEmailChange.bind(this)}/>
		  			</FormControl>
					<FormControl id="password" spacing={4} isRequired>
		  				<Input 
		  				onKeyDown={this.checkForEnter.bind(this)}
		  				variant='outline' bg="black"placeholder="Password" type="password" onChange={this.handlePasswordChange.bind(this)}/>
		  			</FormControl>
		  			<Button id="submit" width="100%" 
		  			onClick={doLogin.bind(this, this.state.email, this.state.password, this.props.callback)} 
		  			colorScheme='whatsapp'>Login</Button>
				</VStack>
				<Notes />
				</center>
					
			</>

		)
	}
}

export {LoginForm}
