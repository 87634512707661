import {Text, Button, Container, HStack } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./dateselect-color-mod.css";
import { Component } from 'react';
import { enGB } from 'date-fns/locale';



class CustomDatePicker extends Component{
  constructor(props){
    super(props);
    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.startDate,
    }

  }

  setStartDate(date){
    this.setState({startDate: date})
  }  

  setEndDate(date){
    this.setState({endDate: date})
  }
  
  ExampleCustomInput = ({ value, onClick }) => (
    <Button colorScheme="whatsapp" variant="outline" className="example-custom-input" onClick={onClick}>
      {value}
    </Button>
  );

  render(){
      return (

        <Container centerContent>
        <HStack >
        <Text> Start:</Text>
        <DatePicker
        selected={this.state.startDate}
        onChange={date => this.setStartDate(date)}
        selectsStart
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        dateFormat="yyyy/MM/dd"
        Locale={enGB}
        customInput={<this.ExampleCustomInput />}
      />
      <Text> End:</Text>
      <DatePicker
        selected={this.state.endDate}
        onChange={date => this.setEndDate(date)}
        selectsEnd
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        minDate={this.state.startDate}
        dateFormat="yyyy/MM/dd"
        Locale={enGB}
        customInput={<this.ExampleCustomInput />}
      
      />

       <Button colorScheme="whatsapp" onClick={this.props.setDateRange.bind(this, this.state.startDate, this.state.endDate)} > Update </Button>
      </HStack>
      
      </Container>

    );
};
}

export default CustomDatePicker;