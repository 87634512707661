import LocalUsers from './LocalUsers';
import {MD5} from 'crypto-js'


function getLocalUser(email){
	let user = LocalUsers.find(user => user.email === email);
	return user
}

function validatePassword(systemPassword, loginPassword){
	if (systemPassword === loginPassword){
		return true
	}
}

function doLogin(email, loginPassword, callback){

	let user = getLocalUser(email);
	let isAuth = false;



	if (validatePassword(user.passwordHash, MD5(loginPassword).toString())){
		isAuth = true;
	}else{
		user = {};
	}
	callback(user, isAuth);
}

export default doLogin;