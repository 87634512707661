import {Table, Thead, Tr, Td, Th, Tbody, Tfoot, SkeletonText, Code, HStack, StackDivider, 
  Button,
  Text,
  } from '@chakra-ui/react';
import { Component } from 'react';
import { CheckIcon, CloseIcon  } from '@chakra-ui/icons'
import Stats from './Stats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import CustomDatePicker from './CustomDatePicker';

import ReactPaginate from 'react-paginate';
import "./pagination.css";
import AppConfig from '../AppConfig';
import { CSVLink } from "react-csv";

 

class ReportTable extends Component{
  constructor(props){
    super(props);
    this.state = {data : [],
    message: "",
    isLoaded: false,
    count: 0,
    displayed: [],
    rowsPerPage: AppConfig.resultsPerPage,
    startDate: this.formatDateSQL(Date.now()),
    endDate: this.formatDateSQL(Date.now()),
    downloadMenu: "",
    }
    this.setDateRange = this.setDateRange.bind(this);
  }


formatDateSQL(date){
    date = new Date(date)
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return  year + '-' + month + '-' + day;
  }
   
  

  componentDidMount() {

    this.fetchTableData(this.state.startDate, this.state.endDate);
  }
    


  fetchTableData(start, end){ 
  this.setState({isLoaded: false}); // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
           'X-Api-Key': AppConfig.APIKey
          },
        body: JSON.stringify(
          { 
            "spoof": AppConfig.spoofAPI,
            "accountUID": AppConfig.merchantKey,
            "dateFrom": this.formatDateSQL(start),
            "dateTo":  this.formatDateSQL(end),
          }
        )
    };


    fetch(AppConfig.APIUrl, requestOptions)
        .then(response => {
            if (response.ok) {
              return response;
            } else {
              this.setState({ data: "Error Fetching Data!" });
              this.setState({ isLoaded: true });
              throw new Error("Error Feching API Response - ".concat(response.status));
          }
        })
        .then(response => {
          return response.json()
        })
        .then(json =>{
          
          this.setState({ data: json.data, message: json.message, isLoaded: true, count: json.data.length });
          this.loadPage(0, this.state.rowsPerPage)
          if (json.data.length !== 0){
          let filename = "MessagingReport_"+this.formatDateSQL(start)+"_"+this.formatDateSQL(end)+".csv"
          this.setState({downloadMenu: 
              <CSVLink data={this.state.data} target="_blank" filename={filename}>
                <Button colorScheme="whatsapp" >CSV Download</Button>
              </CSVLink>}
            )
          }else{ 
            this.setState({downloadMenu: ""})
          }
        })
        .catch(err =>{ 
          this.setState({ message: err.message });
          console.log(err)
          this.setState({ isLoaded: true });
        });
  }

  componentWillUnmount() {
  
  }

  loadPage(start, end){
    this.setState({displayed: this.state.data.slice(start, end)});
  }


  
  handlePageClick(input){
    this.setState({isLoaded:false})
    let selected = input.selected;
    let perPage = this.state.rowsPerPage;
    let start = Math.ceil(selected * perPage);
    let end = Math.ceil(start+perPage);

    this.loadPage(start, end)
    
    this.setState({isLoaded:true})
    
  }

  setDateRange(start, end){
    this.setState({
      startDate: this.formatDateSQL(start),
      endDate: this.formatDateSQL(end),
    }, function(){
      this.fetchTableData(this.state.startDate, this.state.endDate)

      });
    

  }

  render(){
     return (
      
  <div>
  <CustomDatePicker startDate={new Date()} endDate={new Date()} setDateRange={this.setDateRange}/>

  <HStack spacing={4}>
      <Text>{this.state.startDate} : {this.state.endDate}</Text>
      {this.state.downloadMenu}
      
  </HStack>
  <SkeletonText noOfLines={4} spacing="4" isLoaded={this.state.isLoaded}>
  <Stats unitPrice={0.03} count={this.state.count} total={0.09}/><br />

    <ReactPaginate
          previousLabel='←'
          nextLabel='→'
          breakLabel={'...'}
          pageCount={Math.ceil(this.state.data.length/this.state.rowsPerPage)}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={this.handlePageClick.bind(this)}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
  <Code colorScheme="red">{ this.state.message }</Code>


  <Table variant="striped" colorScheme="gray" size="sm">
  <Thead>
    <Tr>
      <Th>Timestamp</Th>
      <Th>Epay Ref </Th>
      <Th>Message Type</Th>
      <Th>Sent - Delivered - Read</Th>
    </Tr>
  </Thead>
  <Tbody>

  
  {
    this.state.displayed.map(message => <Tr key={message.EventId}>
      <Td>{message.Date} {message.Time}</Td>
      <Td>{message.TransactionID}</Td>
      <Td>{message.ChannelPrefix}</Td>
      <Td>
        <HStack 
        divider={<StackDivider borderColor="white.800" />}
        spacing={4}>
          {message.Sent ? <CheckIcon color="green.500"/> : <CloseIcon color="red.500" />}
        
          { message.Delivered === "Y" ? <FontAwesomeIcon icon={faEnvelope} color="green"/> :""}
          { message.Delivered === "N" ? <FontAwesomeIcon icon={faEnvelope} color="gray"/> :""}
          { message.Delivered === "U" ? <FontAwesomeIcon icon={faEnvelope} color="red"/> :""}

          {message.Read ? <FontAwesomeIcon icon={faEnvelopeOpenText} color="green"/> : <FontAwesomeIcon icon={faEnvelopeOpenText} color="gray"/>}
        </HStack>
        </Td>
      </Tr>)
  }
  </Tbody>
  <Tfoot>
    <Tr>
      <Th>Timestamp</Th>
      <Th>Epay Ref </Th>
      <Th>Message Type</Th>
      <Th>Sent - Delivered - Read</Th>
    </Tr>
  </Tfoot>
</Table>
</SkeletonText>


</div>
    )
};
}

export default ReportTable;
