import { StatGroup, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { Component } from 'react';


class Stats extends Component{
  constructor(props){
    super(props); 
    let total = (this.props.count * this.props.unitPrice );
    this.state = {
      unitPrice: this.props.unitPrice || 0.03, 
      count: this.props.count ,
      currency: this.props.currency || "GBP", 
      total: total,
    }
  }
    // var this.state.total = (count * unitPrice)
  
 

  render(){

    return(
      
      <StatGroup>
     
      <Stat>
        <StatLabel>Messages Requested</StatLabel>
        <StatNumber>{this.state.count}</StatNumber>
      </Stat>

      </StatGroup>
    )
  }
}

export default Stats;
